.cardinfo-container{

  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #FFFFFF;
  /* box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.06); */
  border-radius: 29px;

  padding: 20px !important;
  min-width: 200px;
  cursor: pointer;
  transition: all 0.8s ease;
  height: 200px;
  margin-top: 30px;

}
.cardinfo-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardinfo-title h2{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height */

  color: #7E8188;
}

.cardinfo-icon{

  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: all 0.2s;
}

.cardinfo-container p{
  margin-top: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #7E8188;
 
}

.cardinfo-container:hover{
  transform: translateY(-10px);
  padding: 40px 20px !important;
  background-color: var(--primary-cor);
}

.cardinfo-container:hover .cardinfo-title h2,
.cardinfo-container:hover p {
  color: #FFF;

}
.cardinfo-container:hover .cardinfo-icon{
  background-color: #7EBCE2 !important;
 
  width: 32px;
  height: 32px;
}