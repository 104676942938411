

.credit-card-container{

  margin: 0 auto;
  background-color: #FFF;

}

#credit-card{
  margin: 0 auto;
  width: 280px;
  height: 168px;
  perspective: 60rem;
}

#credit-card > div{
  width: inherit;
  height: inherit;

  position: absolute;
  top: 0;
  left: 0;
  /* z-index: 1000; */

  transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

#credit-card .credit-card-front{

  background: url(https://i.imgur.com/M2Q4eGM.png) no-repeat ;
  background-size: cover;
  transform: rotateY(0);
}
#credit-card .credit-card-back{

  background: url(https://i.imgur.com/lh9nyEH.png) no-repeat;
  background-size: cover;
  top: -5px;
  transform: rotateY(-180deg);
}

#credit-card:hover .credit-card-front,
#credit-card:active .credit-card-front{
  transform: rotateY(180deg);
}
#credit-card:hover .credit-card-back,
#credit-card:active .credit-card-back{
  transform: rotateY(0);
}

.credit-card-inputs{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cc-wrapper label{
  font-weight: 500;
  font-size: 1rem;
}

.cc-wrapper input{
  margin-top: .2rem;
  padding: 0.5rem;
  border: .1rem solid #a8a8a8;
  border-radius: .4rem;
  width: 100%;

}

.cc-wrapper input:focus{
  outline: 0.15rem solid  var(--primary-cor);
}

.cc-col-2{
  display: flex;
  gap: 1rem;
  margin-top: 16px;
}

.cc-col-2 .cc-wrapper:nth-child(1){
  flex-basis: 60%;
}

.cc-help{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.credit-card-container button{
  margin-top: 1rem;

  border: 0;
  padding: 1rem;
  width: 100%;
  background-color: var(--primary-cor);
  box-shadow: .0rem .4rem 1.6rem rgba(0,0,0, 0.1);
  color: #FFF;

  cursor: pointer;
  font-weight: 600;
  transition:  background 0.2s;
}

.credit-card-container button:hover{
  background-color: var(--green);
  color: #000;
}

#cc-holder{
  text-transform: uppercase;
}