#bgVendas{
  background: linear-gradient(108.86deg, #25C5AD 16.83%, #40E394 91.1%);
  color: white;
}
#bgProdutos{
  background: linear-gradient(108.86deg, #A258F3 16.83%, #DF6ADD 91.1%);
  color: white;
}

#bgPedidos{
  background: linear-gradient(108.86deg, #FBB517 16.83%, #FD83AC 91.1%);
  color: white;
}

#bg h1{
  font-size: calc(1em + 4vw);
}

.container-card-home{
  display: flex;
  align-items: center;
  overflow: auto;
  /* grid-template-columns: 10% 1fr; */
  padding: 20px;
  gap: 20px;

  flex-wrap: wrap;
}
.container-avatar-home{
  width: 200px;
  height: 200px;
}

.avatar-home{
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  /* border-radius: 50%; */
}

@media(max-width: 596px){
  .container-avatar-home{
    width: 100px;
    height: 100px;
  }
}


@media(max-width: 419px){
  .container-avatar-home{
    width:80px;
    height:80px;
  }
}
