.container-modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 20px;
  gap: 30px;
}

.container-modal img{


  width: 150px;
  height: 150px;
}

.content-modal h2{
  font-size: 24px;
}

.content-modal p{
  font-size: 16px;
}

#btn-primary-modal{
  border: none;
  outline: none;
  background-color: var(--orange);
  color: var(--white);
}
#btn-secundary-modal{
  border: solid 1px var(--orange);
  outline: none;
  
  color: var(--orange);
}
#btn-secundary-modal:hover{ background-color: var(--orange);   color: var(--white);}