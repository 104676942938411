*{
  font-family: 'Exo 2', sans-serif;
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; }

:root{
  /* --primary-cor: #4A78FF; */
  --primary-cor: #1976D2;
  /* --secondary-cor: #7799FF; */
  --secondary-cor: #1A80E5;
  --white: #FFF;
  --green: #31DD61;
  --yellow: #FFC136;
  --orange: #F48635;
  --red: #E8505B;
  --pink: #ef3c63;
  --black: #000;
  --description: #414141;
}

.text-white{ color: var(--white) }
.text-blue{ color: var(--primary-cor) }
#text-blue{ color: var(--primary-cor) }

.swal2-icon-content img{
  width: 200px;
  border: none;
  padding-bottom: 40px;
}

#logo{
  cursor: pointer;
}

#mui-6374{
  display: none;
}

body{
  height: 100vh;
}

.swal2-container {
  z-index: 9999 !important;
}

/* -------- Spiner Load  */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toast-notification{
  background-color: tomato;
}