
.modal-promotion{
    min-height: 400px; 
    position: absolute;
    border-radius: 14px;
    width: 80%;
    margin: 0 auto;
    max-height: 100vh;
    background-color: #FFF;
    padding: 16px;
    overflow: auto;
    top: 50%;
    left: 49%;
    transform: translate(-50%, -49%);
}
.modal-promotion .content-image{
    display: flex;
    align-items: center;
    gap: 20px
}

.modal-promotion .container-image{
    max-height: 300px;
    overflow-y: scroll;
}

.modal-promotion-header{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-promotion-header button{
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 20px;
}

.modal-promotion img{
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.modal-products-table{
    height: 100%;
    max-height: 300px;
    width: 100%;
    overflow-y: scroll;
    margin-top: 20px;
}

.container-list-products{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.container-list-products li{
    list-style: none;
}

.product-promotions-card-container{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 10px;

    /* background-color: #ebf8ff; */
    background-color: #FFF;
    border-radius: 8px;
    padding: 12px;

    position: relative;

    gap: 10px;
}
.product-promotions-card-container h4{
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-promotions-card-infos li span{
    font-weight: 700;
}

.btn-remove-product-promotion{
    position: absolute;

    background-color: var(--red);
    padding: 6px;
    
    border-radius: 8px;
    fill: #FFF;
    top: -20px;
    right: -6px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    transition: all 0.3s ease-in-out;
}

.btn-remove-product-promotion:hover{
    background-color: var(--pink);
}

.btn-remove-product-promotion path{
    fill: #FFF;

}
.container-list-products li span{
    width: 100px;
    text-align: center;
}

.modal-product-infos-container{
    margin-top: 14px;
    display: flex;
    gap: 14px;
    align-items: center;
    
    flex-wrap: wrap;
}

.modal-product-infos-container div{
    display: flex;
    flex-direction: column;
    flex: auto;
}

.modal-product-infos-container div label{
    font-size: 16px;
    font-weight: 600;

}
.modal-products-container label{
    font-size: 16px;
    font-weight: 600;
}

.modal-products-container input:disabled{
    background-color: #f5f5f5;
    color: #000;
    cursor: not-allowed;
}

.modal-product-infos-container div input, .modal-product-infos-container div select{
    /* padding: 10px;
    border-radius: 8px;
    border: 1px solid #000; */
    border-radius: 8px;
    margin-top: 10px;
    width: 100%;
    border: 2px #BBB solid;
    outline: none;
    font-size: 14px;
    padding: 6px 8px;
    transition: 0.2s ease-in-out;
}
.modal-product-infos-container div input:focus, .modal-product-infos-container div select:focus{
    border: 2px var(--primary-cor) solid;
}

.btns-options-type-promotion{
    display: flex;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 8px;

}

@media (min-width: 1920px){
    .modal-promotion{
        width: 50%;
    }
}