.container-loop-address{
    padding: 20px;
    display: 'flex';
    /* overflow: 'auto'; */
    align-items: 'center';
    margin-top: 15px;
}

.container-loop-address .form-edit{
    padding: 10px;
}
/*
.container-loop-address .form-edit:first-child h2:after {
    
    content: " Primario";
    color:  var(--primary-cor);
}
*/
.container-loop-address .form-edit + .form-edit{
    margin-top: 30px;
    border-top: 2px solid var(--primary-cor);
}

.btns-edit-infos button + button{
    margin-left: 10px;
}

.card-style{
    width: 300px;
    height: 200px;

    background: radial-gradient(119.46% 331.85% at -19.46% 100%, #0094FF 0%, #337EE6 61.43%, #18B4DF 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    backdrop-filter: blur(24px);

    border-radius: 30px;

    padding: 20px;
}

.card-style-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: tomato;
    height: 100%;
}

.card-style-dots{
    font-size: 20px;
    text-align: center;
}

.card-style-month{
    text-align: right;
}


@media (max-width: 450px){
    .btns-edit-infos {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .btns-edit-infos button + button{
        margin-left: 0;
    }
}