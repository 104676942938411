.top-selling-products-container{
  padding: 0.75rem;
  background-color: #FFF;
  border-radius: 7px;
  width: auto;
  flex: auto;
  height: 200px;
  overflow-y: scroll;
}

.top-selling-products-container h4{
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: #ABABAB;
}


table.table-top-sellings {
  width: 100%;
  height: 90%;
  text-align: left;
  overflow: hidden;
}
table.table-top-sellings td, table.table-top-sellings th {
  padding: 0.5rem;
}
table.table-top-sellings tr:nth-child(even) {
  /* background: #E0F4F5; */
}
table.table-top-sellings thead {
  color: var(--primary-cor);
  /* background: #E0F4F5; */
}
table.table-top-sellings thead th {
  font-weight: normal;
}
