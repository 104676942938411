.result-view{
    padding: 60px 0;
}
.result-view img{
   cursor: pointer;
   transition: 0.2s ease-in-out;
}
.result-view img:hover{
    transform: scale(1.1)
 }
.result-view img + img{
    margin-left: 10px;
}
.result-view img:first-child{
    border: 2px solid tomato;
}