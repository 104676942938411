.container-modal-cancel{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0;
}

.container-modal-cancel h3{
  font-size: 1.4rem;
}

.container-modal-cancel input{
  margin-top: 0.6rem;

  max-width: 370px;
  padding: 10px;
  width: 100%;
  
  outline: #AAA;
}

.modal-cancel-actions{
  margin-top: 20px;

  display: flex;
  gap: 20px;
}

.modal-cancel-actions button{
  padding: 8px 12px;
  border: none;
  outline: none;
  font-size: 1rem;
  cursor: pointer;
  background-color: #BBB;
  color: #000;
  transition: all 0.2s ease-in;
}
.modal-cancel-actions button:hover { background-color: var(--red); color: #FFF}