body {
  background-color: var(--primary-cor) !important;
  height: 100vh !important;
}

#logo {
  width: 300px;
  margin-top: 20px;
}
.container {
  background-color: var(--primary-cor);
  height: 100vh !important;
}
.content {
  display: flex;
  align-items: center;
  /* justify-content: center; */

  flex-direction: column;
  height: 100vh !important;
  width: 100%;
}

.box-form {
  background-color: var(--white);
  max-width: 80%;
  min-width: 60%;
  border-radius: 20px;

  /* padding: 43px 65px 73px 65px; */
  padding: 0 30px 73px 30px;

  margin-top: 60px;
  margin-bottom: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box-form h1 {
  font-size: 25px;
  color: var(--primary-cor);
}
.link-term {
  font-size: 18px;
  font-weight: bold;
  color: var(--pink);
  text-decoration: underline;
}

.box {
  margin-top: 50px;
}

.box-term p + p{
  margin-top: 30px;
}

.box-term h1{
  margin-top: 30px;
}


.input-column {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
}

.input-column-justify {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.input-row {
  display: flex;
  align-items: center;
}

.input-column div:nth-child(2) {
  margin-left: 15px;
}
.input-column div:nth-child(3) {
  margin-left: 10px;
}

.input-container input:disabled {
  background-color: #afb5b9 !important;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.input-container label {
  margin-top: 10px;
  color: #7c7c7c;
  font-weight: 500;
}

.input-container input,
.input-container textarea,
.input-array {
  /* background-color: #e6eaed; */
  background-color: #dbdfe2;
  height: 42px;

  border: none;
  outline: none;

  border-radius: 3px;

  margin-top: 10px;

  padding: 8px 10px;
}
.input-container select {
  /* background-color: #e6eaed; */
  background-color: #dbdfe2;
  height: 42px;

  border: none;
  outline: none;

  border-radius: 3px;

  margin-top: 10px;

  padding: 8px 10px;
}

.input-container input::placeholder,
.input-array::placeholder {
  color: #a4a4a4;
}
.input-container input:focus,
.input-array:focus {
  border: 1px var(--primary-cor) solid;
}

.c1 {
  width: 420px;
}
.c2 {
  width: 205px;
}
.c4{
  width: 95px;
}

.btn-delivery {
  margin-top: 20px !important;
  margin-left: 0 !important;
}

.btn-delivery button {
  cursor: pointer;
  font-size: 1rem;
  border: none;
  outline: none;
  color: var(--white);
  padding: 10px 30px;

  transition: 0.1s ease-in-out;
}
.btn-delivery button:hover {
  filter: contrast(70%);
}

.btn-delivery button + button {
  margin-left: 20px;
}

.btn-submit {
  width: 100%;
  border: none;
  outline: none;

  background-color: #ef3c63;
  color: var(--white);

  padding: 10px;

  font-size: 20px;
  font-weight: 700;

  margin-top: 3rem;

  cursor: pointer;
}
.btn-termo {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--primary-cor);
  color: var(--white);

  padding: 10px;

  font-size: 20px;
  font-weight: 700;

  margin-top: 3rem;

  cursor: pointer;
  text-align: center;
}

.btn-cpfCnpj {
  background-color: #dbdfe2;
  height: 42px;

  border: none;
  outline: none;

  border-radius: 3px;

  margin-top: 10px;

  padding: 8px 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.btn-cpfCnpj:hover {
  background-color: #b5bec5;
}

.justify-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.adc-input-members {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  outline: none;

  width: 52px;
  height: 52px;

  border-radius: 26px;

  font-size: 28px;
  font-weight: 700;

  background-color: var(--primary-cor);
  color: #fff;
}
.adc-input-members span{ margin-bottom: 5px;}

.container-add-members {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.modalConfimation{
  background-color: #FFF;

  width: 90vw;
  max-height: 90vh;
  overflow: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  border-radius: 15px;
}

.modalConfimation h2{
  text-align: center;
  background-color: var(--primary-cor);
  color: #FFF;
  padding: 16px;
  font-weight: 500;
}

.modalConfirmationContent{
  padding: 16px;
}

.modalConfimation .modalConfimationContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.modalConfirmationContent ul{list-style: none;}
.modalConfirmationContent ul li {list-style: none;  font-weight: 400; font-size: 18px;}
.modalConfirmationContent h3{margin-bottom: 10px; font-weight: 700; font-size: 22px;}
.modalConfirmationContent ul li + li{margin-top: 5px;}
.modalConfirmationContent ul li span{font-weight: 600;  font-weight: 700; font-size: 18px;}
#btn-modal-confirmation{ 
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
}
#btn-modal-confirmation button{
  cursor: pointer;
  outline: none;
  border: none;
  padding: 8px 6px;
  color: #FFF;
  font-weight: 600; 
  font-size: 22px;
}

@keyframes fadeIn{
  from{ opacity: 0;}
  to{opacity: 1;}
}

.animation-fadeIn{
  animation: fadeIn 0s 0.2s ease-in both;
}



@media only screen and (max-width: 1257px) {
  .box-form {
    max-width: 100%;
  }
}

@media only screen and (max-width: 993px) {
  .box-form {
    padding: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .input-column {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
  .input-column div:nth-child(2) {
    margin-left: 0;
  }
  .input-column div:last-child {
    margin-left: 5px;
  }
}

@media only screen and (max-width: 561px) {
  #logo {
    width: 300px;
  }
}

@media(max-width: 491px){
  .input-row{
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
  .input-container {
    width: 100%;
  }
  .c1,.c2{
    width: 100%;
  }
  .input-column div:nth-child(2) {
    margin-left: 0;
  }
  .input-column div:nth-child(3) {
    margin-left: 0;
  }
  .input-column-justify{
    flex-direction: column;
  }
  #logo{
    width: 200px;
  }
}