.container-forgot{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  background-color: #f5f4f6;
}

.container-forgot > .content-forgot{
  height: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}


.icon-box{
  width: 67px;
  height: 67px;
  border-radius: 10px;

  background-color: #CEE4F9;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title-forgot-password{ margin-top: 30px; }

.title-forgot-password h1{
  font-size: 48px;
  color: var(--primary-cor);
}

.title-forgot-password p{
  margin-top: 10px;
  font-size: 20px;
  color: var(--primary-cor);
}

.input-forgot-password{
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-bottom: 2px solid #414141;
  padding-bottom: 5px;
  width: 100%;
}

.input-forgot-password label{
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 10px;
  
  color: #414141;
}

.input-forgot-password div{
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-forgot-password input{
  background-color: transparent;
  border: none;
  outline: none;

  font-size: 20px;
  margin-left: 10px;
}

.input-forgot-password input::placeholder{
  color: #414141;
}

.btn-forgot-next-step{
  background-color: var(--primary-cor);
  color: var(--white);
  
  border: none;
  outline: none;
  padding: 8px 12px;

  font-size: 24px;
  margin-top: 30px;

  /* width: 276px; */
  width: 100%;

  cursor: pointer;
  /* background-color: #d7e7f7; */
}

.btn-forgot-next-step:hover{
  filter: opacity(90%);
}

.forgot-password-card{
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 27px 20px;

  cursor: pointer;
  transition: 0.3s ease-in-out;

  width: 275px;
}

.forgot-password-card:hover{
  background-color: rgb(182, 211, 240);
}

.forgot-password-card-icon1{
  width: 29.25px;
  height: 45px;
}

/* .forgot-password-card-icon2{
  width: 50.57px;
  height: 36.12px;
} */

.forgot-password-card div{
  margin-left: 20px;
  text-align: left;
}

.forgot-password-card div h4{
  color: #7195FF;
}

.forgot-password-card div h3{
  color: #1976D2;
  font-size: 18px;
}

.input-cod-recovery{
  width: 100%;
  font-size: 24px;
  background-color: transparent;
  border: 0;
  outline: none;
  margin-top: 30px;
  border-bottom: 4px solid var(--primary-cor);
  color: var(--primary-cor);
  text-align: center;
  font-weight: bold;
}

.container-cronometro{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

}
.container-cronometro span{
  color: #2E2E2E;
  margin-right: 10px;
}


@media(max-width: 576px){
  .container-forgot > .content-forgot{
    width: 90%;
    height: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
  }
}