.loading-container{
  position: absolute;
  background-color: #FFF;
  width: 100%;
  height: 100vh;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: center;
}

.table-category-title{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
}

.table-category-title div button{
  border: none;
  outline: none;
  padding: 8px 6px;
  color: #FFF;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.table-category-title div button:hover{
  filter: brightness(0.9);
}

.container-catalog {
  padding: 1rem 1rem 1rem 1rem ;
}

.container-catalog table{
  width: 100%;
  border-spacing: 0 0.5rem;
}

.container-catalog table th{
  color: rgb(53, 52, 52);
  padding: 1rem 2rem;
  text-align: left;
  line-height: 1.5rem;
}

.card-catalog-container{
  width: 100%;
  display: flex;
  max-height: 200px;
  align-items: center;

  overflow-x: auto;
}

.card-catalog-container div + div{
  margin-left: 20px;
}

.card-catalog{
  height: 100px;
  min-width: 300px;
  /* background-color: #1976d2 !important; */

  display: flex;
  align-items: center;
  justify-content: center;
}

.card-catalog:hover{
  filter: brightness(0.9);
}

.card-catalog span{
  font-size: 24px;
  font-weight: 500;
  color: #FFF;
}

.container-catalog table td{
  padding: 1rem 2rem;
  border: 0;
  background: rgba(245, 244, 244, 0.788);
  border-radius: 0.25rem;
}

.btn-adc-primary{
  font-size: 15px;
  padding: 6px 8px;


  background-color: var(--primary-cor);
  outline: none;
  color: #FFF;
  border: none;
  cursor: pointer;

  transition: 0.2s ease-in;
}
.btn-cancel-primary{
  font-size: 15px;
  padding: 6px 8px;


  background-color: var(--red);
  outline: none;
  color: #FFF;
  border: none;
  cursor: pointer;

  transition: 0.2s ease-in;
}

.btn-adc-item{
  font-size: 15px;
  padding: 6px 8px;
  background-color: #1976d2;

  border: none;
  outline: none;
  color: #FFF;

  cursor: pointer;

  transition: 0.2s ease-in;
  border-radius: 4px;
}
.btn-adc-item:hover{
 /*
  border: none;
  border: 2px #1976d2 solid;
  color: #FFF;
  background-color: #1976d2;
 */
 background-color: #1681ec;
}

.btn-adc-item:disabled{
  background-color: #BBB;
}

.btn-adc-item span{
  font-weight: 800;
  margin-right: 10px;
}

@media screen and (max-width: 446px){
  .btn-adc-item{
    width: 100%;
  }
}

.item-container{
  display: flex;
  align-items: center;
}

.item-container img{
 margin-right: 10px;
}

.item-container h5{
  font-size: 12px;
  font-weight: 400;
  text-align: justify;
}

.modal-row{
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-modal-products{
  margin-top: 20px;

  /* display: grid; */
  /* grid-template-columns: 450px 190px; */
}

.modal-products-container{
  margin-top: 10px;
  
}

.modal-products-container input{
  margin-top: 10px;
  width: 100%;

  border: 2px #BBB solid;
  outline: none;

  font-size: 14px;
  padding: 6px 8px;

  transition: 0.2s ease-in-out;
}

.modal-products-container select{
  margin-top: 10px;
  width: 100%;

  border: 2px #BBB solid;
  outline: none;

  font-size: 14px;
  padding: 6px 8px;

  transition: 0.2s ease-in-out;
}
.modal-products-container select:focus{
  border: 2px #1976d2 solid;
  

}
.modal-products-container textarea{
  margin-top: 10px;
  width: 100%;

  border: 2px #BBB solid;
  outline: none;

  font-size: 14px;
  padding: 6px 8px;

  transition: 0.2s ease-in-out;
}

.modal-products-container input:focus{
  border: 2px #1976d2 solid;
}
.modal-products-container textarea:focus{
  border: 2px #1976d2 solid;
}

.modal-products-box{
  margin-top: 30px;
  background-color: rgb(231, 241, 255);

  padding: 12px;
}

.input-prince{
  font-size: 14px;
  padding: 6px 8px;

  transition: 0.2s ease-in-out;

  border: 2px #BBB solid;
  outline: none;

}
.input-prince:focus{

  border: 2px #1976d2 solid;
}

.scrollbar
{
	float: left;
	background: #F5F5F5;
  height: 300px;
  cursor: pointer;
}

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	height: 8px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #1976d2;
}



/* ----------- Previw Imagem ----------- */
.heading{
  text-align: center;
  font-family: 'Alice', serif;
  font-size: 2rem;
  font-weight: bold;
  color: #f7f7f7;
  padding-top: 2rem;
}
#file[type="file"] {
	display: none;
}
.label-holder {
	width: 100%;
	height: 50px;
  margin-top: 3em;
  display: grid;
  place-items: center;
  
}
.label{
  height: 50px;
  width: 200px;
  background-color: var(--primary-cor);
  color: white;
  display: grid;
  place-items: center;
  font-size: 2.5rem ;
  cursor: pointer;
  
}

.result{
  min-height: 100%;
  max-height: auto;
  width: 100%;
  /* background-color: #272c34; */
  background-color: #ebebeb;
  margin-top:1rem ;
   display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; 
  
}

.result img{
  width: 320px;
  height: 320px;
  object-fit: cover;
 /* padding: 0.75rem; */
}

.img-previw-container{
  display: flex;
  margin: 0;
  padding: 0;
  padding: 0.75rem;
}
.img-previw-container button{
  border: none;
  outline: none;
  border-radius: 50%;
  margin-left: -10px;
  margin-top: -10px;
  width: 40px;
  height: 40px;
  background-color: var(--primary-cor);
  color: #FFF;
  z-index: 5;
  cursor: pointer;
}
/* ----------- End Previw Imagem ----------- */

.pagination-container{
  margin-top: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination-content button{
  background-color: var(--primary-cor);
  color: var(--white);
  padding: 14px 8px;
  border: none;
  cursor: pointer;
}
.pagination-content button:disabled{
  background-color: rgb(139, 139, 139);
}
.pagination-content input{
  padding: 14px 8px;
  border: none;
  width: 50px;
  outline: none;
  cursor: pointer;
}


.service-modal-planos{
  font-weight: bold;
}

.service-modal-planos span{
  color: var(--primary-cor);
  cursor: pointer;
}