.profile-img{
  width: 40px;
  border-radius: 20px;
}

.nav-edit-container{
  display: flex;
  flex-direction: column;
}

.nav-edit-content ul{
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.nav-edit-content ul li{
  list-style: none;
  height: 70px;
}

.nav-edit-content ul li + li{
  margin-left: 10px;
}

.nav-edit-content ul li button{
  padding: 8px 6px;

  border: none;
  outline: none;
  cursor: pointer;
  transition: ease-in-out 0.2s;

  font-size: 16px;
  color: #FFF;
  height: 100%;
  width: auto;

}

.nav-edit-content ul li button:hover{background-color: tomato;}

.form-edit{
  width: 100%;
  transition: 0.5s ease-in-out;
}

.form-edit div + div{
  margin-top: 30px;
}
.btn-action-edit {
  border: none;
  outline: none;

  color: #FFF;

  font-size: 14px;
  padding: 2px 8px;

  cursor: pointer;
}

.form-edit-row{
  margin-top: 5px;

  display: flex;
 flex-direction: column;
}
.form-btn-edit-row{
  margin-top: 5px;
  display: flex;

}

.form-edit h4,
.form-edit label{
  font-size: 16px;
  color: rgb(134, 134, 134);

  font-weight: 400;
}

.form-edit-row h3{
  font-weight: 500;
  font-size: 16px;

  color: rgb(51, 50, 50),
}

.form-edit-row input{
  width: 100%;
  border: 0;
  border-bottom: 2px #ddd solid;

  outline: none;
  font-size: 16px;

  font-weight: 300;
}
.form-edit-row input:focus{
  border-bottom: 2px #1A80E5 solid;
}
.form-edit-row textarea:focus,
.form-edit-row select:focus{
  border: 2px #1A80E5 solid;
}
#input-focus-width:focus{
  width: 100%;
}
.form-edit-row input:disabled,
.form-edit-row select:disabled,
.form-edit-row textarea:disabled{
  color: #8f8f8f;
  width: 100%;
}

.form-edit-row textarea{
  outline: none;
  border: 2px #ddd solid;
  font-size: 16px;
  padding: 4px;
  margin-left: 0;
  transition: all .2s ease;
  resize: vertical;
}

.form-edit-row select{
  outline: none;
  border: 2px #ddd solid;
  font-size: 16px;
  padding: 4px;

  transition: all .2s ease;
}

.status-perfil{
  border: none;
  outline: none;

  margin-left: 20px;
  color: #FFF;

  font-size: 14px;
  padding: 2px 8px;

  cursor: pointer;
}

.status-perfil:disabled{
  pointer-events: none;
}

.form-edit-row-time{
  display: flex;
  align-items: center;
}
.form-edit-row-time div{
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 0;
}

.closed-input-time{
  background-color: var(--red);
  color: #FFF;
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
}
