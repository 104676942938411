.MuiPaper-elevation2{ box-shadow: none !important; }

.container-input-pedidos{
  display: flex;
  justify-content: space-between;
}

.search-pedidos {
  border-left: none !important; 
  border-right: none !important; 
  border-top: none !important; 
  border-bottom: 1px solid;
  padding: 4px;
  outline: none;
  width: 75%;

}
.search-pedidos:focus {
  border-left: none !important; 
  border-right: none !important; 
  border-top: none !important; 
  border-bottom: 2px #4A78FF solid;
}

.container-input-pedidos input:focus{ border: 1px var(--primary-cor) solid; }

.btn-search{
  display: flex;
  align-items: center;
  
  padding: 0 8px;
  background: #4A78FF;
  color: white;
  
  border: none;
  outline: none;

  cursor: pointer;
}
.btn-search:hover{
 filter: contrast(80%);
}

.btn-search svg{  
  margin-left: 10px;
}

.filter-btn{
  width: 100%;
  display: flex;
  /*justify-content: space-between;*/
  
}

.filter-btn button{
  padding: 2px 2px;
  display: flex;
  align-items: center;

  background-color: transparent;
  border: none;
  outline: none;

  transition: 0.5s ease-in-out;

  font-size: 12px;
  cursor: pointer;
}
.filter-btn button:hover{
  background-color: #C4C4C4;
}
.filter-btn button + button{
  margin-left: 20px;
}

.filter-btn button div{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.container-pedidos{
  height: 600px;
  overflow: scroll;

}

.header-modal-pedido{
  background-color: var(--primary-cor);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-modal-devolution{
  background-color: var(--primary-cor);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* ------------- Modal ------------ */

.status-pedido{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.first-details-pedido-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details-pedido-container{
  display: flex;
  flex-direction: column;

  margin-top: 30px;
}

.details-pedido-content h3{
  font-weight: 700;
  font-size: 18px;
  color: #616161;
  margin-bottom: 8px;
}

.details-pedido-content h4{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130.9%;
  /* or 18px */

  color: #616161;
}

.table-pedidos{
  width: 100%;
  background: linear-gradient(0deg, #EAEAEA, #EAEAEA), #EAEAEA;
  padding: 8px;
  max-height: 400px;
  height: auto;
  overflow-y: auto;

}
.coluna-pedidos{
  width: 100%;
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.obs-pedido{
  margin-top: 12px;
  display: block;
  width: 100%;
  height: 2px;
  background-color: #CCC;
}

.footer-modal{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.btn-pedidos{
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 20px;
  gap: 20px;
}

.btn-pedidos button{
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  border: none;
  outline: none;

  max-width: 168px;
  height: 57px;
  padding: 12px;
  font-size: 20px;
  font-weight: 500;
  transition: 0.2s ease-in-out;

  color: #FFF;
}

.btn-pedidos button:hover{
  filter: brightness(0.9);
}

.btn-pedidos button img{
  margin-left: 5px;
  width: 25px;
}

.btn-closed-modal{
  
  display: flex;
  align-items: center;
  justify-content: center;
  
 

  cursor: pointer;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #cbecff;

  font-size: 20px;
  font-weight: 700;
  color: rgb(109, 109, 109);

  z-index: 900px;
  border: 0;
  outline: 0;

  transition: 0.2s ease-in-out;
}

.btn-closed-modal:hover{
  filter: brightness(0.9);
}

@media only screen and (max-width: 1340px) {
  .filter-btn{
    width: 100%;
    display: grid;
    grid-template-columns: 122px 142px;
    justify-content: left;

    overflow: scroll;
  }
  .filter-btn button {
    margin-bottom: 10px;
  }
  .filter-btn button + button{
    margin-left: 0;
  }
  .filter-btn button:nth-child(even) {
    margin-left: 20px;
  }
}