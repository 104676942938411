*{
  box-sizing: border-box;
}
a{
  text-decoration: none;
  
}

#logo-login{
  width: 80%;
  
}

.container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.left{
  padding-top: auto;
  width: 50%;
  height: 100vh;
  background: var(--primary-cor);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left{
  color: var(--white);
}

.left h1{
  font-size: 3rem;
}

.left h2{
  font-size: 2rem;
  font-weight: 300;
}

.right{
  width: 50%;
  height: 100vh;
  background: var(--white);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.right-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;
}

.form-login{
  display: flex;
  flex-direction: column;

  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 10%;
}


.container-input{
  margin-top: 20px;
  padding: 0;
  border-bottom: 2px var(--primary-cor) solid;

  display: flex;
  justify-content: space-between;
  align-items: center;

}

.container-input input{
  width: 100%;
  margin: 0 !important;
}

.form-login div img{
  margin-right: 10px;

}

.form-login input{
  margin-top: 30px;

  font-size: 1.6rem;
  font-weight: 300;
  color: var(--primary-cor);

  border: none;

  outline: none;

  padding-bottom: 4px;
}

.form-login input::placeholder{
  color: var(--primary-cor);
  font-size: 1.5rem;
}

.form-login input:focus{
}

.form-login input:not( :placeholder-shown ){
  font-weight: 500;
}

.btn-container{
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 10% !important;
  border: none !important;
}

.btn{
  cursor: pointer;
  color: var(--white);

  font-size: 20px;

  border: 0;
  outline: 0;

  padding: 12px 0;

  margin-top: 20px;
}

.btn-primary{  transition: 150ms ease-in-out; border: none; background-color: var(--white); border: 2px var(--primary-cor)  solid; color: var(--primary-cor); }
.btn-secondary{ transition: 150ms ease-in-out; background-color: var(--secondary-cor); }

.btn-primary:hover { background-color: var(--primary-cor); color: var(--white); }
.btn-secondary:hover { filter: brightness(85%); }

.forgot-password{
  background-color: transparent;
  border: none;
  outline: none;

  color: var(--primary-cor);
  font-size: 20px;

  margin-top: 10%;

  cursor: pointer;
}

.remember-password{
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: var(--primary-cor);
}

.remember-password input{ margin: 0;  color: var(--primary-cor) !important;}

@media(max-width: 976px){
  .container{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .right{
    width: 100%;
  }
  .right-box{
    margin-top: 10%;
  }
  .left{
    width: 100%;
    display: flex;
    text-align: center;
  }

}
@media(max-width: 491px){
  .left{
    padding-top: 30%;
    padding-bottom: 10%;
  }
  .right-box{
    padding-top: 30%;
  }
}


@media(max-width: 464px){
  .left h1{
    font-size: 10vw;
  }
  .left h2{
    font-size: 7vw;
  }
  .form-login{
    width: auto;
  }
}