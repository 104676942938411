.button-modal-closed{
  border: none;
  outline: none;

  background-color: #e6e6e6;
  width: 30px;
  height: 30px;
  border-radius: 15px;
 
  display: flex;
  align-items: center;
  justify-content: center;
  
  color: #353535;
;
  font-size: 15px;
  font-weight: 500;

  cursor: pointer;
}

.img-product-container{
  margin-top: 2.5rem;

  width: 100%;
  display: flex;
  justify-content: space-between;
}

.img-product-container img:first-child{
  border: 3px solid rgb(17, 102, 212);
}

.img-product-container img{
  cursor: pointer;
}

.info-product-container {
  margin-top: 2.5rem;
}

.info-product-container h3{
  margin-top: 15px;
  font-weight: 400;
  cursor: pointer;
}