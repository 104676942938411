.card-insight-dash-container{
  background-color: var(--white);
  min-height: 5.625rem;
  width: auto;
  flex: auto;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.8rem;
}

.card-insight-dash-content{
  display: flex;
  align-items: center;
  gap: 0.875rem;

  flex-wrap: wrap;
}

.card-insight-dash-icon{
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--primary-cor);

  display: flex;
  align-items: center;
  justify-content: center;
}

.card-insight-dash-info{
  display: flex;
  flex-direction: column;
}

.card-insight-dash-info h4{
  color: #ABABAB;
  font-size: 1rem;
  font-weight: 500;
}
.card-insight-dash-info h5{
  color: #414141;
  font-size: 1.2rem;
  font-weight: 700;
}

