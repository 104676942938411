.service-plans-container{
  width: 100%;
  
}

.service-plans-content{
  margin: 0 auto;
  width: 400px;
  padding: 12px;
  background-color: #FFF;
  cursor: pointer;
  transition: all 0.3s ease;
}


.service-plan-header-card{
  display: flex;
  align-items: center;

  transition: all 0.3s ease;
}
.service-plan-header-card img{
  width: 100px;
}
.service-plan-header-card h1{
  color: var(--primary-cor);
  margin-left: 20px;
}
.service-plans-content:hover > .service-plan-header-card{
  padding: 8px;
}
.service-plans-content:hover > p{
  margin-top: 40px;
  margin-bottom: 40px;

}
.service-plans-content:hover > button{
  visibility: visible;
  margin-bottom: 10px;
}

.service-plans-content p{
  transition: all 0.3s ease;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  width: 90%;
  text-align: center;
}

.service-plans-content p span{
  color: var(--primary-cor);
}


.serice-plans-btn{
  width: 100%;
  background-color: var(--primary-cor);
  color: var(--white);
  font-size: 18px;
  border: none;
  outline: none;
  padding: 8px;
  transition: all 0.2s ease;
  cursor: pointer;

}

.service-plans-prince{
  color: var(--primary-cor);
  display: flex;
  align-items: center;
  justify-content: center;

}
.service-plans-prince span:first-child{
  align-self: center;
  font-size: 18px;
}
.service-plans-prince h1{
  font-size: 68px;
}
.service-plans-prince h1 span{
  align-self: flex-end;
  font-size: 18px;
}

.service-plans-prince-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: var(--primary-cor);
  margin-bottom: 20px;
}

@media(max-width: 480px){
.service-plans-content{
  width: 300px;
}
}