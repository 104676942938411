.title-section{
  margin-bottom: 20px;

  display: flex;
  align-items: center;

  background-color: #D7E1FF;
  padding: 8px;
  width: 100%;
}
.title-section h2{
  font-weight: 600;
}
.title-section-icon{
  width: 100px;
}
.title-section-icon div{
  display: flex;
  align-items: center;
  justify-content: center;
   
  width: 50%;
  height: 50%;

  border-radius: 50%;

  margin-right: 10px;
  background-color: #1a80e5a9;

  padding: 10px 25px;
}

.title-section-icon div svg{ 
  width: calc(1.375rem + 0.4vw);
  height: 100%;
  /* padding: 10px; */
  color: #ffffff;
}

@media(max-width:1043px){
  .title-section-icon div svg{ 
    width: calc(1.375rem + 1.5vw);
  }
}

@media(max-width:490px){
  .title-section{
    flex-direction: column;
    align-items: flex-start;
  }
}